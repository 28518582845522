import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/HomePage.vue";
import About from "../views/AboutPage.vue";
import Products from "../views/ProductsPage.vue";
import Contacts from "../views/ContactPage.vue";
import ProductDetail from "../views/ProductDetailPage.vue";
import Klobasy from "../views/Products/KlobasyPage.vue";
import Salamy from "../views/Products/SalamyPage.vue";
import Udene from "../views/Products/UdenePage.vue";
import Pastety from "../views/Products/PastetyPage.vue";
import Parky from "../views/Products/ParkyPage.vue";
import Sunky from "../views/Products/SunkaPage.vue";
import Zabijackove from "../views/Products/ZabijackovePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/klobasy",
    name: "Klobasy",
    component: Klobasy,
  },
  {
    path: "/parky",
    name: "Parky",
    component: Parky,
  },
  {
    path: "/pastety",
    name: "Pastety",
    component: Pastety,
  },
  {
    path: "/salamy",
    name: "Salamy",
    component: Salamy,
  },
  {
    path: "/udene-masa",
    name: "Udene",
    component: Udene,
  },
  {
    path: "/sunky",
    name: "Sunky",
    component: Sunky,
  },
  {
    path: "/zabijackove-speciality",
    name: "Zabijackove",
    component: Zabijackove,
  },
  {
    path: "/product/:productId",
    name: "ProductDetail",
    component: ProductDetail,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: "hash",
});

export default router;
