<template>
  <header>
    <div class="container">
      <nav class="navigation-container">
        <div class="slogan">
          <p>Pre tých, ktorým záleží čo jedia...</p>
        </div>
        <div class="logo">
          <router-link to="/">
            <img src="../assets/RMJ_logo_white.png" alt="logo" />
          </router-link>
        </div>
        <div class="mobile-toggle" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul
          :class="{ navigation: true, active: mobileMenuOpen }"
          class="menu-items"
        >
          <li>
            <router-link
              class="link"
              :to="{ name: 'Home' }"
              @click="closeMobileMenu"
              >Domov</router-link
            >
          </li>
          <li
            @mouseover="showCategories"
            @mouseleave="hideCategories"
            @click="toggleCategoriesMenu"
          >
            <router-link class="link" :to="{ name: 'Products' }"
              >Produkty</router-link
            >
            <transition name="slide-down">
              <ul
                v-show="showCategoriesMenu"
                class="categories-menu"
                @mouseover="showCategories"
                @mouseleave="hideCategories"
              >
                <li>
                  <router-link class="link" :to="{ name: 'Klobasy' }">
                    <img :src="require(`@/assets/Categories/KLOBASY.jpg`)" />
                    Klobásy
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Parky' }">
                    <img :src="require(`@/assets/Categories/PARKY.jpg`)" />
                    Párkoviny
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Pastety' }">
                    <img :src="require(`@/assets/Categories/PASTETY.jpg`)" />
                    Paštéty
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Salamy' }">
                    <img :src="require(`@/assets/Categories/SALAMY.jpg`)" />
                    Salámy
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Sunky' }">
                    <img :src="require(`@/assets/Categories/SUNKY.jpg`)" />
                    Šunky
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Zabijackove' }">
                    <img
                      :src="require(`@/assets/Categories/ZABIJACKOVE.jpg`)"
                    />
                    Zabíjačkové<br />
                    špeciality
                  </router-link>
                </li>
                <li>
                  <router-link class="link" :to="{ name: 'Udene' }">
                    <img :src="require(`@/assets/Categories/UDENE.jpg`)" />
                    Údené mäsá
                  </router-link>
                </li>
              </ul>
            </transition>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'About' }"
              >O nás</router-link
            >
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Contacts' }"
              >Kontakt</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showCategoriesMenu: false,
      mobileMenuOpen: false,
    };
  },
  mounted() {
    const menuLinks = document.querySelectorAll(".link");
    menuLinks.forEach((link) => {
      link.addEventListener("click", this.closeMobileMenu);
    });
  },
  beforeUnmount() {
    const menuLinks = document.querySelectorAll(".link");
    menuLinks.forEach((link) => {
      link.removeEventListener("click", this.closeMobileMenu);
    });
  },
  methods: {
    toggleCategoriesMenu() {
      this.showCategoriesMenu = !this.showCategoriesMenu;
    },
    showCategories() {
      this.showCategoriesMenu = true;
    },
    hideCategories() {
      this.showCategoriesMenu = false;
    },
    toggleMenu() {
      if (window.innerWidth <= 768) {
        this.mobileMenuOpen = !this.mobileMenuOpen;
        this.showCategoriesMenu = false;
      }
    },
    closeMobileMenu() {
      if (window.innerWidth <= 768) {
        this.mobileMenuOpen = false;
        this.showCategoriesMenu = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: url("../assets/headerBG.jpg") center center;
  z-index: 99;
  transition: all 0.4s ease-in-out;
  border-bottom: 3px solid #faedcd;
}

.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

nav {
  padding: 12px 0;
  transition: 0.5s ease-in-out;
  margin: 0 auto;
  width: 90%;
  ul,
  .link {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      text-transform: uppercase;
      margin: 0 10px;
    }
    .link {
      font-size: 18px;
      font-family: "Montserrat";
      transition: 0.5s ease-in-out;
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      align-self: center;
      cursor: pointer;
      &:hover {
        color: #9f000d;
      }
    }
  }
  .underlink {
    list-style-type: circle;
    font-size: 12px;
    font-family: "Montserrat";
    transition: 0.5s ease-in-out;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    align-self: center;
    cursor: pointer;
    &:hover {
      color: #9f000d;
    }
  }
}

.logo {
  margin-right: 20px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    transition: 0.5s ease-in-out;
  }
}

.categories-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: url("../assets/headerBG.jpg") center center;
  border: 3px solid #faedcd;
  padding: 10px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  min-width: 80%;
  padding-left: 50px;
  padding-right: 50px;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 5px;
  }

  img {
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    text-align: center;
    margin-top: 5px;
    &:hover {
      color: #9f000d;
    }
  }
}

.categories-menu:hover {
  color: #fff;
}

.slide-down-enter-active {
  transition: all 0.5s;
  opacity: 0;
}

.slide-down-enter-to {
  max-height: 500px;
  opacity: 1;
}

.slide-down-leave-active {
  transition: all 0.5s;
  opacity: 0;
  transition-delay: 0.8s;
}

.mobile-toggle {
  display: none;
}

.navigation.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: 3px solid #faedcd;
  background-color: rgba(18, 18, 18, 1);
  z-index: 99;
}

.navigation.active li {
  margin: 10px 0;
}

.navigation.active .link,
.navigation.active .underlink {
  color: #fff;
}

.navigation.active .link:hover,
.navigation.active .underlink:hover {
  color: #9f000d;
}

.slogan {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Montserrat";
  margin-left: 20px;
  left: 0;
  position: absolute;
}

@media (max-width: 1180px) and (min-width: 769px) {
  .slogan {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo {
    margin-right: auto;
    margin-left: 10px;
  }
  .slogan {
    left: 15%;
    font-size: small;
  }

  .mobile-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    width: 30px;
    height: 22px;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: transform 0.3s;
    }

    &.active span:nth-child(2) {
      transform: translateX(-100%);
      opacity: 0;
    }

    &.active span:nth-child(1) {
      transform: rotate(45deg) translateY(5px);
    }

    &.active span:nth-child(3) {
      transform: rotate(-45deg) translateY(-5px);
    }
  }

  .navigation {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(18, 18, 18, 1);
    z-index: 99;
    padding: 10px;
  }

  .navigation.active {
    display: flex;
  }

  .navigation li {
    margin: 10px 0;
  }

  .navigation .link,
  .navigation .underlink {
    color: #fff;
  }

  .navigation .link:hover,
  .navigation .underlink:hover {
    color: #9f000d;
  }

  /* Updated mobile styles for categories-menu */
  .categories-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    top: auto;
    left: auto;
    transform: none;
    background-color: rgba(18, 18, 18, 1);
    padding: 10px;
    margin: 10px auto;
    width: 100%;
    border: 3px solid #faedcd;
    background: none;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 10px 0;
      color: rgba($color: #ffffff, $alpha: 1);
    }

    img {
      display: none;
    }

    .underlink {
      color: rgba($color: #ffffff, $alpha: 1);
      background-color: rgba($color: #121212, $alpha: 1);
    }

    .link {
      color: rgba($color: #ffffff, $alpha: 1);
      text-decoration: none;
      text-align: center;
      &:hover {
        color: #9f000d;
      }
    }
  }
}
</style>
