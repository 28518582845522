<template>
  <div class="container">
    <div class="window">
      <div class="info">
        <the-product-card
          :text="text"
          :headline="headline"
          :image-src="imageSrc"
        />
      </div>
      <div class="products">
        <the-product-list :products="filteredProducts" />
      </div>
    </div>
    <div class="footer-wrapper">
      <the-footer />
    </div>
  </div>
</template>


<script>
import TheProductCard from "../../components/TheProductCard.vue";
import TheFooter from "../../components/TheFooter.vue";
import TheProductList from "../../components/TheProductsList.vue";
import image from "../../assets/Categories/UDENE.jpg";
//import ProductService from "../../services/Products.js";
import json from "../../../public/butchery.json";
export default {
  components: {
    TheProductCard: TheProductCard,
    TheFooter: TheFooter,
    TheProductList: TheProductList,
  },
  name: "UdenePage",
  data: () => ({
    headline: "Údené mäsá",
    text: "",
    imageSrc: image,
    products: [],
  }),
  computed: {
    filteredProducts() {
      return this.products.filter((product) => {
        return product.kategoria === "údené mäsá";
      });
    },
  },
  mounted() {
    this.fetchProducts(); // Fetch products when the component is mounted
  },
  methods: {
    fetchProducts() {
      this.products = json;
      /*ProductService.chlieb()
        .then((response) => {
          this.products = response.data;
        })
        .catch((error) => {
          console.error(error);
        });*/
    },
  },
};
</script>


<style scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/IMIDZ_6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1; /* Ensure the background stays behind other elements */
  overflow-y: auto; /* Enable scrolling for the content */
  overflow-x: hidden;
}

.info {
  margin-top: 120px;
  text-align: center;
  color: rgba(27, 27, 27, 1);
}

.footer-wrapper {
  margin-top: 0;
  flex-shrink: 0;
}

.window {
  min-height: 100%;
}
</style>